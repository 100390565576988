import axios from '@axios'

const route = 'manager_room'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllRoom(){
      return axios.get(`${route}/get_all`)
    },
    store(context, role) {
      return axios.post(route, role)
    },
    update(context, room) {
      return axios.put(`${route}/${room.id}`, room)
    },
    updateRoomBusy(context, room) {
      return axios.put(`${route}/update_room_busy/${room.id}`, room)
    },
    delete(context, role) {
      return axios.delete(`${route}/${role.id}`)
    },
    storeRemovedProduct(context,removedProduct){
      return axios.post(route+'/store_removed_product', removedProduct)
    },
    updateStatusRoom(context,data){
      return axios.put(route+'/update_status_room/' + data.room_id, data)
    }
  },
}
