import axios from '@axios'

const route = 'report/rds'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    get(context ,filter) {
      return axios.post(route,filter)
    },
    
    exportExcel(context, filter){
      return axios.post(route + '/export_excel',filter,{responseType: 'blob'})
    },
    exportPdf(context, filter){
      return axios.post(route + '/export_pdf',filter,{responseType: 'blob'})
    }

  },
}
