import axios from '@axios'

const route = 'register/service'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, service) {
      return axios.post(route, service)
    },
    update(context, service) {
      return axios.put(`${route}/${service.id}`, service)
    },
    delete(context, service) {
      return axios.delete(`${route}/${service.id}`)
    },
  },
}
