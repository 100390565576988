import axios from '@axios'


export default {
  namespaced: true,
  state: {
    user : null,
    authorization:null
  },
  getters: {
    getUser(state){
      return state.user
    }
  },
  mutations: {
    setUser(state,user){
      state.user = user
    },
    setAuthorization(state,authorization){
      state.authorization = authorization
    }

  },

  actions: {

    login(context, login) {
      return axios.post('login', login ,{headers:{callbackerror:true}})
    },
    logout(context ) {
      return axios.post('logout', {headers:{callbackerror:true}} )
    },

    // update(context, role) {
    //   return axios.put(`${route}/${role.id}`, role)
    // },
    // delete(context, role) {
    //   return axios.delete(`${route}/${role.id}`)
    // },
  },
}
