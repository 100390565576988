import axios from '@axios';

const route = 'manager_room'

export default {
  namespaced: true,
  state: {
    room:{},
    checkout: [],
    stage:'new_sale',
    paymentMethods:[],
    paymentMethodsPartial:[],
    note:'',
    additional:{value:0,additional_obs:''},
    discount:{value:0,discount_obs:''},
    selectResponsible:{},
    originPartialPayment:'checkin',
    dataNotafiscal:{}
  },
  getters: {
    getPaymentAttach(state){
      if (state.room.current_accommodation.payment_attachment){
        return state.room.current_accommodation.payment_attachment.id
      }
      return null
    },
    getPaymentMethodsPartial(state){
      return state.paymentMethodsPartial
    },
    
    getPaymentMethodsPartialTotal(state){
      //soma todos os produtos
      return  state.paymentMethodsPartial.reduce((accumalator, item)=>{
        return accumalator + parseFloat(item.value)
      },0)
    },

    getOriginPartialPayment(state){
      return state.originPartialPayment
    },
    getAccommodationValue(state){
      const check_in = state.room.current_accommodation.check_in
      const check_out = state.room.current_accommodation.check_out
      let tariff = 0
      if(state.room.current_accommodation.tariff_manual == 'not'){
        tariff = state.room.current_accommodation.tariff.value
      }else{
        tariff = state.room.current_accommodation.tariff_manual_value
      }


      const diffInMs = new Date(check_out) - new Date(check_in)
      const days = diffInMs / (1000 * 60 * 60 * 24)

      const total  = parseFloat(days) * parseFloat(tariff)
      return {
        days,
        tariff,
        total
       }
    },

    getAdditional(state){
      return state.additional
    },
    getDiscount(state){
      return state.discount
    },
    getRoom(state){
      return state.room
    },
    getItemsCheckout(state){
      return state.checkout
    },
    getNote(state){
      return state.note;
    },
    getCustomer(state) {
      return state.customer
    },
    getStage(state){
      return state.stage
    },
    getSubtotal(state){
      //soma todos os produtos
      return  state.checkout.reduce((accumalator, item)=>{
        return accumalator + (item.sale_price * item.pivot.quantity)
      },0)
    },
    getChange(state,getters){
     const totalReceive =  state.paymentMethods.reduce((acc,method)=>{
        return acc + parseFloat(method.value)
      },0)

      const sumPaymentPartial = state.paymentMethodsPartial.reduce((sum ,payment)=>{
        return sum + parseFloat(payment.value)
      },0)


      const result = (parseFloat(totalReceive)+parseFloat(sumPaymentPartial)) - parseFloat(getters.getTotalToPay)
      if(result < 0 ){
        return 0
      }
      return result
    },
    //total do checkout
    getTotalToPay(state,getters){
      return  (  getters.getTaxation +            //impostos
         parseFloat(getters.getSubtotal) +      //subtotal produtos /servicos
         parseFloat(state.additional.value )) - //valor adicional
         (parseFloat(state.discount.value))       //valor desconto + ADIANTAMENTO
         
    },
    //total a pagar 
    getToPay(state,getters){
      
      const getTotalToPay  = getters.getTotalToPay
      const partialTotal  =  parseFloat(getters.getPaymentMethodsPartialTotal)
      const getPaymentMethodsTotal = getters.getPaymentMethodsTotal
      return getTotalToPay - (partialTotal + getPaymentMethodsTotal)

    },
    getpaymentMethods(state){
      return state.paymentMethods
    },

    getPaymentMethodsTotal(state){
      return state.paymentMethods.reduce((acc,method)=>{
        return acc + parseFloat(method.value)
      },0)
    },

    

    remainingValue(state,getters){
      let sumPayment = 0
      let sumPaymentPartial = 0
      sumPayment = state.paymentMethods.reduce((sum ,payment)=>{
        return sum + parseFloat(payment.value)
      },0)
      sumPaymentPartial = parseFloat(getters.getPaymentMethodsPartialTotal)

      return getters.getTotalToPay - (sumPayment+sumPaymentPartial)
    },

    activeBtnCheckout(state,getters){

       return Math.round(getters.remainingValue) // arredonda mas o numero deve ser toa pequeno q sera igua a zero
    },
    getTaxation(state){
      //Somatorio de imposto ISS por serviço
      const iss = state.checkout.reduce((acc,item)=>{
        if (item.origin == 'service'){
          //ex : ((3% /100) * valor de venda) * quantidade
          return acc + ((parseFloat(item.iss)/100)*parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
        }

        return acc
      },0)


      //Somatorio de imposto ISS por hospedagem
      let iss_accommodation = 0

      if(state.room.current_accommodation.with_iss == 'yes'){
        iss_accommodation = state.checkout.reduce((acc,item)=>{
          if (item.origin == 'accommodation'){
            //ex : 3% * hospedage
            return acc + (0.03 *parseFloat(item.sale_price))
          }
          return acc
        },0)
      }


      return iss + iss_accommodation
    },

    getSelectecResposible(state){
      return state.selectResponsible
    },

    //PAGAMENTO SEPARADO
    remainingValueSeparate(state,getters){
      let responsible = state.selectResponsible

      //soma todas as formas de pagamentos do responsavel
      let sumPayment = 0
      sumPayment = state.paymentMethods.reduce((sum ,payment)=>{
        if(payment.origin == responsible.origin && payment.id == responsible.id ){
          return sum + parseFloat(payment.value)
        }
        return sum
      },0)

      return getters.getTotalToPaySeparate - sumPayment
    },
    getTotalToPaySeparate(state,getters ){

      return getters.getSubTotalSeparateWithTax
    },
    getSubTotalSeparateWithTax(state,getters){
      let responsible = state.selectResponsible
      const sum = getters.getItemsCheckout.reduce((acc,item)=>{
        if(item.responsible){ //se tem responsavel pelo pagamento
          //verifica a origin e o id dos item com o responsavel
          if(item.responsible.origin == responsible.origin && item.responsible.id == responsible.id ){
            let iss =0
            let iss_accommodation = 0
            if(item.origin == 'service'){
               iss = ((parseFloat(item.iss)/100)*parseFloat(item.sale_price)) * parseFloat(item.pivot.quantity)
            }
            //iss de hospedagem
            if(item.origin == 'accommodation'){
               iss_accommodation = 0.03 * parseFloat(item.sale_price)
            }
            return acc + parseFloat(item.sale_price) + iss + iss_accommodation
          }
        }
        return acc
      },0)

      return sum
    },

    getpaymentMethodsSeparate(state){
      return state.paymentMethods
    }

  },
  mutations: {
    //seta dados nota fiscal
    setDataNotaFiscal(state,dataNotafiscal){
      state.dataNotaFiscal = dataNotafiscal
    },
    setPaymentMethodsPartial(state,partialPayment){
      state.paymentMethodsPartial = partialPayment
    },
    setOriginPartialPayment(state,originPartialPayment){
      state.originPartialPayment = originPartialPayment
    },
    //PAGAMENTO SEPARADO
    addItemResponsible(state,item){
      const index = state.checkout.indexOf(item)

      if(item.checked){
        state.checkout[index].responsible = state.selectResponsible
      }else{
        delete state.checkout[index].responsible
      }

       //gabiarra pra forca a reacao do vue
       let checkout = state.checkout
       state.checkout = []
       state.checkout = checkout

    },
    setSelectecResposible(state,responsible){
      state.selectResponsible = responsible
    },
    clearResponsible(state){
      state.selectResponsible = {}
    },
    addPaymentMethodSeparate(state,payment){
        
        payment.origin = state.selectResponsible.origin
        payment.id = state.selectResponsible.id
        state.paymentMethods.push(payment) //adicona pagamento
    },
    removePaymentMethodSeparate(state,payment){
      state.paymentMethods.splice(state.paymentMethods.indexOf(payment), 1);
    },

    // END PAGAMENTO SEPARADO

    setMethodPayment(state,paymentMethod){
      state.paymentMethod = paymentMethod
    },

    setAdditional(state,addicional){
      state.additional = addicional
    },
    setDiscount(state,discount){
      state.discount = discount
    },
    setRoom(state,room){
      state.room = room
    },
    cancelSale(state){
      state.customer= null
      state.checkout= []
      state.gratuities=[]
      state.tax=0
      state.stage='new_sale'
      state.paymentMethods=[]
      state.sale_id = null
      state.showCheckout = true
    },
    setShowCheckout(state,showCheckout){
       state.showCheckout = showCheckout
    },
    setNote(state,note){
      state.note = note
    },

    setPaymentMethod(state,paymentMethods){
      state.paymentMethods = paymentMethods
    },

    addPaymentMethod(state,payment){
      if( payment.clearPayments ){ //limpa todos os pagamnetos
        state.paymentMethods = []
      }
      const paymentMethod = state.paymentMethods.find((item)=>{
        return payment.method == item.method
      })

      if(paymentMethod != undefined){
        state.paymentMethods.splice(state.paymentMethods.indexOf(paymentMethod), 1)  // apaga o method de pagamento
      }

      state.paymentMethods.push(payment) //adicona pagamento
    },

    removePaymentMethod(state,payment){
          state.paymentMethods.splice(state.paymentMethods.indexOf(payment), 1);
    },
    setCheckout(state,checkout){
        state.checkout =[]// gabiarra pra força reacao do vue
        state.checkout =checkout
    },
    addCheckout(state, item) {

      let search_item = state.checkout.find((search_item) => {
       return  (search_item.id == item.id && search_item.origin_checkout == item.origin_checkout )
      })
      if (search_item == undefined) {
        item.qtd_checkout = 1
        state.checkout.push(item)
      } else {
        let index = state.checkout.indexOf(search_item)
        state.checkout[index].qtd_checkout += 1
      }

      if (state.customer == null){
        state.customer = {id:1,name:'Cliente Padrão'}
      }
      state.showCheckout = true

      //gambiarra para força a a reacao do vue
      let checkout = state.checkout
      state.checkout = null
      state.checkout = checkout

    },

    setStage(state,stage){
      state.stage = stage
    },

    clearCheckoutSeparete(state){
      state.selectResponsible = {}
      state.paymentMethods = []
      state.note = ''
      state.additional = {value:0,additional_obs:''},
      state.discount = {value:0,discount_obs:''}

      //retira atribuicao de responsavel
      state.checkout.forEach(item => {
        delete item.responsible
      });


    },
    clearCheckout(state){
      state.selectResponsible = {}
      state.room = {}
      state.checkout =  []
      state.stage = 'payment'
      state.paymentMethods = []
      state.paymentMethodsPartial = []
      state.note = ''
      state.additional = {value:0,additional_obs:''},
      state.discount = {value:0,discount_obs:''}
    }
  },

  actions: {

    getUser(){
      return axios.get('helper/get_user') 
    },

    cashsOpen(){
      return axios.get('helper/get_opened_cashs') 
    },

    storeSale({state,getters}){
      const sale = {
        room_id:state.room.id,
        accommodation_id: state.room.current_accommodation.id,
        checkout: state.checkout,
        dataNotaFiscal: state.dataNotaFiscal,
        paymentMethods:state.paymentMethods,
        subtotal: getters.getSubtotal,
        tax:getters.getTaxation,
        discount:state.discount,
        additional: state.additional,
        total_receive:getters.getPaymentMethodsTotal,
        change:getters.getChange,
        note:state.note,
      }
      
      return axios.post(route+'/checkout',sale ) //{responseType: 'blob'}
    },

    exportPdfCheckout({state}){
      const data = {
        accommodation_id: state.room.current_accommodation.id
      }
      return axios.post(route+'/export_pdf_checkout', data, {responseType: 'blob'}) //
    },
    //SALVA ANEXO 
    storeAttach({ state },attach){
      let formData = new FormData()
      if(state.originPartialPayment == 'reserve'){
        formData.append('reserve_id', state.room.current_accommodation.id)
      }else if(state.originPartialPayment == 'checkout') {
        formData.append('accommodation_id', state.room.current_accommodation.id)
      }
                
      formData.append('attach', attach.attach[0])
      formData.append('attach_obs', attach.attach_obs)
      return axios.post(route+'/attach_checkout', formData, {'Content-Type': 'multipart/form-data'}) //
    },
    
    //BAIXAR ANEXO
    getDownloadAttach(context,attachId){
      return axios.get(route+'/attach_checkout/' + attachId,{responseType: 'blob'})
    },
    
    //PAGAMENTO PARCIAL
    storePartialPayment({state,getters} ){
      const partialPayment = {
        room_id:state.room.id,
        accommodation_id: state.room.current_accommodation.id,
        checkout: state.checkout,
        paymentMethods:state.paymentMethods,
        subtotal: getters.getSubtotal,
        tax:getters.getTaxation,
        discount:state.discount,
        additional: state.additional,
        total_receive: getters.getPaymentMethodsTotal,
        change:getters.getChange,
        note:state.note,
      }

      return axios.post(route+'/partial_payment', partialPayment)
      
    },
    //CARREGA OS PAGAMNETOS PARCIAS
    getPartialPayment({state}){
      const accommodation_id =  state.room.current_accommodation.id
      return axios.get(route+'/get_partial_payment/'+accommodation_id);
    },
    //REMOVE PAGAMENTO PARCIAL
    destroyPaymentPartial(context,{justify,payment_item_id}){
      return axios.post(route+'/remove_partial_payment/'+payment_item_id, {justify:justify});
    },

    //SALVA PAGAMENTO PARCIAL NA RESERVA
    storeResevePartialPayment({state,getters},data){
      const reserve_id = state.room.current_accommodation.id
      
      const partialPayment = {
        room_id:state.room.id,
        reserve_id: state.room.current_accommodation.id,
        checkout: state.checkout,
        paymentMethods:state.paymentMethods,
        subtotal: getters.getSubtotal,
        tax:getters.getTaxation,
        discount:state.discount,
        additional: state.additional,
        total_receive: getters.getPaymentMethodsTotal,
        change:getters.getChange,
        note:state.note,
        cash_id:data.cash_id
      }

      return axios.post('reserve/partial_payment/'+reserve_id, partialPayment)
    },
    //CARREGA OS PAGAMNETOS ADIATADO NA RESERVA
    getResevePartialPayment({state}){
      const reserve_id =  state.room.current_accommodation.id
      return axios.get('reserve/get_partial_payment/'+reserve_id);
    },

  },
  
  


}
