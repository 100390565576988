import axios from '@axios'

const route = 'report/intern_consumptions'
export default {
  namespaced: true,
  state: {
    intern_consumptions: [],
  },
  getters: {
    intern_consumptions: state => state.intern_consumptions,
  },
  mutations: {},
  actions: {
    getAll(context, params) {
      return axios.get(route, { params })
    },
    exportExcel(context, params) {
      return axios.get(route + '/export_excel', {
        params: params,
        responseType: 'blob'} )
    },
    loadInternConsumptions(context, params) {
      axios.get(route,{ params }).then(response => {
        context.state.intern_consumptions = response
      })
    }

  },
}
