import axios from '@axios'

const route = 'manager_accommodation'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search, filter }) {
      return axios.post(`${route}/checkouts?page=${page}&items_per_page=${itemsPerPage}&search=${search}`,filter)
    },
    // store(context, card) {
    //   return axios.post(route, card)
    // },
    // update(context, card) {
    //   return axios.put(`${route}/${card.id}`, card)
    // },
    // delete(context, card) {
    //   return axios.delete(`${route}/${card.id}`)
    // },
    printCheckoutPdf(context,checkout){
      return axios.get(`${route}/export_pdf/${checkout.id}`,{responseType: 'blob'})
    }

  },
}