import axios from '@axios';

const route = 'reserve/reserve'
export default {
  namespaced: true,
  state: {
    guests:[],
    guestsUnknown:[]

  },
  getters: {
    getGuests(state){
      return state.guests.concat(state.guestsUnknown)
    },

  },
  mutations: {
  setGuests(state,guests){
    state.guests = guests
  },
  setGuestsUnknown(state,guests){
    guests.forEach((guest)=>{
      guest.origin = 'unknown'
    })

    state.guestsUnknown = guests
  },

  addGuest(state,guest) {
      const result = state.guests.find((item) => {
          return item.id == guest.id
      })
      if (!result) {
          state.guests.push(guest)
      }
  },

  addGuestUnknown(state,guestUnknown) {
      state.guestsUnknown.push(guestUnknown)
  },

  removeGuest(state,guest) {
      const index = state.guests.indexOf(guest)
      state.guests.splice(index, 1)
  },

  removeUnknown(state,unknown) {
    const index = state.guestsUnknown.indexOf(unknown)
    state.guestsUnknown.splice(index, 1)
  }


  },
  actions: {
    getAll(context, { itemsPerPage, page, search ,filter}) {
      return axios.post(`reserve/index?page=${page}&items_per_page=${itemsPerPage}&search=${search}`,filter)
    },
    store({state}, reserve) {
      reserve.guest = state.guests
      reserve.guest_unknown = state.guestsUnknown
      return axios.post(route, reserve)
    },
    update({state}, reserve) {
      reserve.guest = state.guests
      reserve.guest_unknown = state.guestsUnknown
      return axios.put(`${route}/${reserve.id}`, reserve)
    },
    cancelReserve(context, data) {
      return axios.post(`reserve/delete/${data.id}`,data)
    },
    getRooms(context){
      return axios.get('helper/get_rooms')
    },
    printVoucher(context, reserve){
      return axios.get('reserve/reserve_voucher_export_pdf/'+reserve.id,{responseType: 'blob'})
    },
    getReserveItems(context,reserve){ 
      return axios.get('reserve/reserve_items/'+reserve.id)
    },

    verifyRoom(context, params){
      return axios.post('reserve/verify_room',params)
    },

    resendVoucher(context,data){
      return axios.post('reserve/resend_voucher/'+data.reserve_id,{email:data.email})
    },

    //Retorna os caixas abertos
    getOpenedCashs(context){
      return  axios.get('helper/get_opened_cashs')
    },
    
    //Retorna o usuario logado
    getUser(context){
      return  axios.get('helper/get_user')
    }


  },
}
