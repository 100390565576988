import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import App from './App.vue'
import vmask from './plugins/v-mask'
import "./plugins/vue-toast-notification.js"
import vuetify from './plugins/vuetify'
import "./plugins/vuetify-money.js"
import router from './router'
import store from './store'

Vue.use(VueSweetalert2);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  vmask,
  render: h => h(App),
}).$mount('#app')
