import axios from '@axios'

const route = 'cash'

export default {
  namespaced: true,
  state: {
    cash:null
  },
  getters:{
    getCash(state){
       return state.cash
    }

  },
  mutations: {
    setCash(state,cash){
      state.cash = cash
    }
  },
  actions: {
    getCashs(){
      return axios.get(route )
    },
    getCashes(context,{page,itemsPerPage,search}){
      return axios.get(route + `/list?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    storeVerified(context, data){
      return axios.post(route+'/verified/'+data.cash_id, data,{headers:{callbackerror:true}})
    },
    getExtract(context, cash_id){
      return axios.get(route + '/extract_cash/'+cash_id)
    },
    reportAnalytical(contex, cash_id ){
      return axios.get(route + '/export_pdf_report_analitical/'+cash_id,{responseType: 'blob'})
    },
    viewReportAnalytical(contex, cash_id ){
      return axios.get(route + '/view_report_analitical/'+cash_id)
    },
    //CONSOLITED CASH REPORT
    exportReportConsolidatedCash(contex, cash_id ){
      return axios.get(route + '/export_pdf_report_analitical/'+cash_id,{responseType: 'blob'})
    },

    getReportConsolidatedCash(contex, filter){
      return axios.post(route + '/get_report_consolidated',filter)
    },
    exportReportConsolidatedCashPdf(contex, filter){
      return axios.post(route + '/export_pdf_report_consolidated',filter,{responseType: 'blob'})
    }

  },
}
