import axios from '@axios'

const route = 'register/card'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, card) {
      return axios.post(route, card)
    },
    update(context, card) {
      return axios.put(`${route}/${card.id}`, card)
    },
    delete(context, card) {
      return axios.delete(`${route}/${card.id}`)
    },
  },
}
