<template>
    <v-row>
        <v-col>
            <v-autocomplete
             :search-input.sync="searchQueryLocal" 
             outlined
             dense 
             rounded 
             filled 
             class="mt-2"
             v-model="roomSelected"
             :loading="loading"
             :items="items"
             return-object
             hide-details
             placeholder="Localizar Hóspedes"
             @click="inicialize"
             clearable
             :prepend-icon="icons.mdiMagnify"
             ></v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import { mdiMagnify, mdiClose } from '@mdi/js'
import axios from '@/plugins/axios'
export default {
  data: () => ({
    items: [],
    searchQueryLocal: null,
    roomSelected: null,
    loading: false,
    icons: {
      mdiMagnify,
      mdiClose,
    },
  }),

  watch: {
    searchQueryLocal(val) {
      this.inicialize()
    },
    roomSelected(val) {
        // this.$router.push({ name: 'manager_room', params: { room_id: val.id } })
    },
  },
  created() {
    this.inicialize()
  },
  methods: {
    inicialize() {
      if (this.loading) return false

      this.loading = true
      const data = {
        search: this.searchQueryLocal,
      }
      const route = 'helper/get_guest_accommodation'

      axios
        .post(route, data)
        .then(response => {
          this.items = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>
</style>