import axios from '@axios'

const route = 'role'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    
    store(context, role) {
      return axios.post(route, role)
    },
    
    update(context, role) {
      return axios.put(`${route}/${role.id}`, role)
    },
    
    delete(context, role) {
      return axios.delete(`${route}/${role.id}`)
    },
    
    getPermission(content, role_id){
      return axios.get(route+'/permissions/'+role_id) 
    },
    storePermission(constex,data ){
      return axios.post(route+'/permissions/'+data.role_id, data.permission)
    }
  },
}
