import axios from '@axios'

const route = 'system/config'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    storeOrUpdate(context, config) {
      return axios.post(route, config)
    },

    loadConfig(context) {
      return axios.get(route)
    },

    storeSmtp(context, smtp ){
      return axios.post(route+'/smtp', smtp)
    },
    destroySmtp(context,smtp){
      return axios.delete(route+'/smtp/'+smtp.id)
    }



  },
}
