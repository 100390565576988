// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({

  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}


    //baseURL: 'https://api.aerohotel.chesshotel.ocatech.com.br/api/',
    // baseURL: 'http://chess-hotel.test/api/',
    baseURL: process.env.VUE_APP_URL_API


})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (error) => {
    console.log('erro request')
  }
)

axiosIns.interceptors.response.use(
  config => {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (error) => {
    console.log('error response')

if(error.response.config.headers.callbackerror == undefined){


    switch (error.response.status) {
      case 422:
        // intercepta erro 422
        var errors = error.response.data

        var html = "";
        if (typeof (errors) == 'object') {
          for (let error in errors.errors) {
            html += "<p>" + errors.errors[error] + "</p>";
          }
        }
        if(typeof(errors) == 'string'){
          html = errors
        }

        Vue.swal({
          confirmButtonColor: '#16b1ff',
          position: "center",
          html: html,
          icon: "error",
          title: "Erro",
        });
        break
      case 400:
        // intercepta erro 422
        var errors = error.response.data

        var html = "";
        if (typeof (errors) == 'object') {
          if(!!errors.errors) {
            for (let error in errors.errors) {
              html += "<p>" + errors.errors[error] + "</p>";
            }
          }else if(!!errors.message) {
            html = errors.message
          }
        }
        if(typeof(errors) == 'string'){
          html = errors
        }

        Vue.swal({
          confirmButtonColor: '#16b1ff',
          position: "center",
          html: html,
          icon: "error",
          title: "Erro",
        });
        break
      case 401:
        // intercepta erro 401
        Vue.$toast.error('Não autorizado')
        break
      case 403:
        // intercepta erro 403
        Vue.$toast.error("Não autorizado")
        break
      case 500:
        // intercepta erro 500
        Vue.$toast.error("Erro no servidor")
        break
      case 400:
        // intercepta erro 500
        Vue.$toast.error("Erro no servidor")
        break

      default:
        Vue.$toast.error("Erro inesperado")
        break
    }
  }
    return Promise.reject(error)

  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
