import axios from '@axios'

const route = 'report/open_account'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context ) {
      return axios.post(route)
    },
    
    exportExcel(context){
      return axios.post(route + '/export_excel',{},{responseType: 'blob'})
    },
    exportPdf(context ){
      return axios.post(route + '/export_pdf',{},{ responseType: 'blob' })
    },
    exportDetailsPdf(context, room){
      return axios.post(route + '/export_details_pdf/'+room.id,{},{ responseType: 'blob' })
    }

    // store(context, card) {
    //   return axios.post(route, card)
    // },
    // update(context, card) {
    //   return axios.put(`${route}/${card.id}`, card)
    // },
    // delete(context, card) {
    //   return axios.delete(`${route}/${card.id}`)
    // },
  },
}
