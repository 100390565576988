import axios from '@axios'

const route = 'cash'

export default {
  namespaced: true,
  state: {
    cash:null
  },
  getters:{
    getCash(state){
       return state.cash
    }

  },
  mutations: {
    setCash(state,cash){
      state.cash = cash
    }
  },
  actions: {
    getCash(){
      return axios.get(route + '/opened_cash')
    },
    getExtract(context ){
      return axios.get(route + '/extract_cash')
    },
    openCash(context, initialCash) {
      return axios.post(route + '/open', {
        initial_cash: initialCash
      })
    },
    closeCash(context ,obsCloseCash) {
      return axios.post(route + '/close',{obs:obsCloseCash})
    },

    openLastCash(context){
      return axios.post(route + '/open_last')
    },

    getOldCash(context,lastTenCash){
      return axios.get(route + '/old_cashs/'+lastTenCash)
    },

    reopenOldCash(context,cashId){
      return axios.post(route + '/reopen_old_cashs/'+cashId)
    },

    registerOldCash(context,cash){
      return axios.post(route + '/register_old_cash',cash)
    },

    //registrar um deposito no caixa
    deposit(context,deposit){
      return axios.post(route + '/store_deposit',deposit)
    },
    //registrar saque
    withdrawal(context,withdrawal){
      return axios.post(route + '/store_withdrawal',withdrawal)
    },

    //intern_consumption
    internConsumption(context,internConsumption){
      return axios.post(route + '/intern_consumption',internConsumption)
    },

    //intern_consumption
    otherExpenses(context,otherExpenses){
      return axios.post(route + '/other_expenses',otherExpenses)
    },

    //get products
    getProducts(){
      return axios.post('helper/get_products')
    }
  },
}
