import axios from '@axios'

const route = 'register/pdv'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, pdv) {
      return axios.post(route, pdv)
    },
    update(context, pdv) {
      return axios.put(`${route}/${pdv.id}`, pdv)
    },
    delete(context, pdv) {
      return axios.delete(`${route}/${pdv.id}`)
    },
  },
}
