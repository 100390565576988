import axios from '@axios'

const route = 'report/production_by_company'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context ,filter) {
      return axios.post(route,filter)
    },
    exportExcel(context,filter){
      return axios.post(route + '/export_excel',filter,{responseType: 'blob'})
    },
    getAllAccomodation(context,filter){
        return axios.post(route + '/get_all_accommodation'+`?page=${filter.page}&items_per_page=${filter.itemsPerPage}`,filter)
    }
    
    
  },
  
}
