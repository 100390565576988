import axios from '@axios'

const route = 'register/guest'
export default {
  namespaced: true,
  state: {
    familiars: []
  },
  getters: {
    getFamiliars(state){
      return state.familiars
    }
  },
  mutations: {
    setFamiliars(state,familiars){
      state.familiars = familiars
    }
  },
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, role) {
      return axios.post(route, role)
    },
    update(context, role) {
      return axios.put(`${route}/${role.id}`, role)
    },
    delete(context, role) {
      return axios.delete(`${route}/${role.id}`)
    },
    exportPdf(context,guest){
      return axios.get(route + '/export_pdf/'+guest.id,{responseType: 'blob'})
    },
    printSheetGuest(context,guest){
      return axios.get(route + '/sheet_guest',{responseType: 'blob'})
    }

  },
}
