import axios from '@axios'

const route = 'report/breakfast'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, filter) {
      return axios.post(route,filter)
    },
    exportExcel(context){
      return axios.get(route + '/export_excel',{responseType: 'blob'})
    }

    // store(context, card) {
    //   return axios.post(route, card)
    // },
    // update(context, card) {
    //   return axios.put(`${route}/${card.id}`, card)
    // },
    // delete(context, card) {
    //   return axios.delete(`${route}/${card.id}`)
    // },
  },
}
