import axios from '@axios'

const route = 'dashboard'

export default {
  namespaced: true,
  state: {

  },
  getters:{


  },
  mutations: {

  },
  actions: {
    getReceive(){
      return axios.get(route+'/receive')
    },
    getSalesOverview(){
      return axios.get(route+'/sales_overview')
    }
  },
}
