import axios from '@axios'

const route = 'report/product_sale'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context ,filter) {
      return axios.post(route,filter)
    },
    
    exportExcel(context, filter){
      return axios.post(route + '/export_excel',filter,{responseType: 'blob'})
    },
    exportPdf(context, filter){
      return axios.post(route + '/export_pdf',filter,{responseType: 'blob'})
    }

    // store(context, card) {
    //   return axios.post(route, card)
    // },
    // update(context, card) {
    //   return axios.put(`${route}/${card.id}`, card)
    // },
    // delete(context, card) {
    //   return axios.delete(`${route}/${card.id}`)
    // },
  },
}
