import axios from '@axios'

const route = 'register/pix'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, pix) {
      return axios.post(route, pix)
    },
    update(context, pix) {
      return axios.put(`${route}/${pix.id}`, pix)
    },
    delete(context, pix) {
      return axios.delete(`${route}/${pix.id}`)
    },
  },
}
