import axios from '@axios'

const route = 'register/product'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    // store(context, room) {
    //   return axios.post(route, room)
    // },
    // update(context, room) {
    //   return axios.put(`${route}/${room.id}`, room)
    // },
    // delete(context, room) {
    //   return axios.delete(`${route}/${room.id}`)
    // },
    storeProductActive(context,product){
      return axios.post(route+'/update_product_active/' + product.id,product)
    },

    updateProduct(){
      return axios.get(route+'/update_product')
    }
  },
}
