import axios from '@axios'

const route = 'register/room'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page, search }) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}&search=${search}`)
    },
    store(context, room) {
      return axios.post(route, room)
    },
    update(context, room) {
      return axios.put(`${route}/${room.id}`, room)
    },
    delete(context, room) {
      return axios.delete(`${route}/${room.id}`)
    },
    getCategory(){
      return axios.get(route+'/get_category')
    }
  },
}
